import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Input, Button, Message, Spinner } from 'theme-ui'

const styles = {
  msg: {
    mb: 0
  },
  button: {
    display: `block`,
    mx: `auto`
  }
}

const NewsletterForm = ({
  // handleSubmit,
  // canSubmit,
  // submitting,
  // message,
  // success
}) => {
  const [success, setSuccess] = useState()

  return (
    <form id="sib-form" method="POST" action="https://bd7618ea.sibforms.com/serve/MUIEAD783AOPq5n5pz45CtbC7z6jhEhuGmvKGOmEgn9AmbD8hFeKWfJl5mG6pX6R-fDyUmNfP6u3Xq2XXSDzW9aKmujxzA311vVmJX2eq3ZMqv4OT_hmfA-rMa87eCvT0q67FM43VZWZEmD4Uzdm4y85ks_HOPe-_H8cGg8v6LA7QtILAPMDjDeJv1Lesb_AbbJBnFC_QtZcH5E2" target='_blank' onSubmit={() => setTimeout(() => setSuccess(true), 200)}>
      {success && (
        <Message
          variant="success"
          sx={styles.msg}
        >
          Danke für's Anmelden!
        </Message>
      )}
      {!success && (
        <>
          <Box variant='forms.row'>
            <Input
              name='EMAIL'
              type='email'
              placeholder='Email Adresse'
              aria-label='Email Adresse'
              autocomplete="email"
              id="EMAIL"
              required
            />
          </Box>
          <input type="text" name="email_address_check" defaultValue="" style={{display: 'none'}}></input>
          <input type="hidden" name="locale" value="de"></input>
          <input type="hidden" name="html_type" value="simple"></input>
          <Button
            type='submit'
            variant={success ? 'disabled' : 'primary'}
            disabled={success}
            sx={styles.button}
            form='sib-form'
          >
            Anmelden
          </Button>
        </>
      )}
    </form>
  )
}

export default NewsletterForm

// NewsletterForm.propTypes = {
//   handleSubmit: PropTypes.func,
//   canSubmit: PropTypes.bool,
//   submitting: PropTypes.bool,
//   message: PropTypes.string,
//   success: PropTypes.bool
// }
