import React from 'react'
import { Link as GLink } from 'gatsby'
import { Text, Link, Card } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import Section from '@components/Section'
import useMailChimp from '@helpers/useMailChimp'

const NewsletterCompact = props => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success
  } = useMailChimp()

  return (
    <Section aside title='Newsletter' {...props}>
      <Card variant='paper'>
        <Text variant='h4'>
          Du möchtest deine Therapie noch besser gestalten?
        </Text>
        <Text variant='small' style={{ marginBottom: '12px' }}>
          Dann abonniere meinen Newsletter und erhalte neue Artikel direkt per Email!
        </Text>
        <NewsletterForm
          {...{ handleSubmit, canSubmit, submitting, message, success }}
        />
        <Text variant='xsmall' style={{ marginTop: '12px' }}>
          Abmeldung jederzeit möglich. Normalerweise 1 bis 2 Emails im Monat, maximal alle 2 Wochen.{' '}
          {/* Du erhältst neue Artikel wenn ich sie veröffentliche, und evtl. Hinweise zu meinen Angeboten, sollte ich z.B. irgendwann mal ein Buch veröffentlichen. */}
          Einwilligung zum Newsletter-Versand gemäß meiner{' '}
          <Link as={GLink} to="/datenschutz">
            Datenschutzerklärung
          </Link>
          .
        </Text>
      </Card>
    </Section>
  )
}

export default NewsletterCompact
