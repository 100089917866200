import { useState } from 'react'
// import addToMailchimp from 'gatsby-plugin-mailchimp'

const useMailChimp = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const email = data.get('email')
    data.set('fields[email]', email)
    data.delete('email')
    data.set('ml-submit', '1')
    data.set('anticsrf', 'true')
    try {
      await fetch('https://static.mailerlite.com/webforms/submit/o6o0l6', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        },
        body: data
      })
      setSuccess(true)
    } catch (e) {
      setError(false)
    }
    setSubmitting(false)
  }

  const canSubmit = !success || error
  const message = success && 'will this be shown?'

  return {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
    error
  }
}

export default useMailChimp
